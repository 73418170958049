<template>
    <v-container id="shift-review" fluid>
        <v-card>
            <v-card-title>
                <h2>Set Password</h2>
            </v-card-title>
            <v-spacer />
            <v-card-text>
                <div class="d-flex">
                    <v-btn color="primary" dark @click="SetPassword">
                        Set Password
                    </v-btn>
                </div>

                <v-dialog v-model="dialog" max-width="500px">
                    <v-form>
                        <v-card>
                            <v-card-title>
                                <span class="headline">Change Password</span>
                            </v-card-title>
                            <v-card-text>
                                <v-text-field
                                    v-model="oldPassword"
                                    label="Old Password"
                                    :type="'password'"
                                    required />
                                <v-text-field
                                    v-model="newPassword"
                                    label="New Password"
                                    :type="'password'"
                                    :rules="[rules.required, rules.min, rules.max]"
                                    required />
                                <v-text-field
                                    v-model="confirmNewPassword"
                                    label="Confirm New Password"
                                    :type="'password'"
                                    :rules="[rules.required, passwordConfirmationRule]"
                                    required />
                            </v-card-text>
                            <v-card-actions>
                                <div class="flex-grow-1" />
                                <v-btn color="quaternary" text @click="close">
                                    Cancel
                                </v-btn>
                                <v-btn color="quaternary" text @click="save">
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-form>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import axios from "axios";
import { handleError } from "@/services/errorUtility";
export default {
    components: {},
    data() {
        return {
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
            dialog: false,
            rules: {
                required: value => !!value || "Required.",
                min: v => v.length >= 8 || "Min 8 characters",
                max: v => v.length <= 50 || "Max 50 characters"
            },
        };
    },

    computed: {
        passwordConfirmationRule() {
            return this.newPassword === this.confirmNewPassword || "Password must match";
        },
    },

    methods: {
        SetPassword() {
            this.dialog = true;
        },
        close() {
            this.dialog = false;
        },
        closeError() {
            this.error = false;
            this.errorMessage = null;
        },
        async save() {
            let response = null;
            try {
                let body = {
                    OldPassword: this.oldPassword,
                    NewPassword: this.newPassword,
                };
                response = await axios.post("api/Security/ChangePassword", body);
            } catch (error) {
                handleError(error);
                return;
            }
            const { dispatch } = this.$store;
            dispatch("authentication/setPasswordSuccess", response.data);
        },
    },
};
</script>
